// @flow

// Third party
import * as React from 'react';
import { WrappedTypeahead } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import itly from 'itly';
import { get, some } from 'lodash';
import { filter } from 'lodash/collection';
import uniqBy from 'lodash/uniqBy';
import queryString from 'qs';

import { Opportunity, Org, OrgUser, Partner, PartnerAccount } from 'data/entities';
import { statusChoices } from 'data/entities/bdrequest';
import { OrgUserNameOnly } from 'data/entities/orguser';
import {
  fetchAccountBySlug,
  fetchAccountIdsByXBRecordId,
  listPartnerMappedAccountsByAccount,
} from 'data/repositories/accounts';
import { createBdRequest } from 'data/repositories/bdrequests';
import { fetchNetworkProfile } from 'data/repositories/networkprofile';
import { bdRequestMentionableUsers } from 'data/repositories/orgusers';
import { fetchPartnerBySlug, fetchPartnerIdsByXBRecordId } from 'data/repositories/partners';
import type { SearchIndexItem } from 'data/repositories/search';
import { OrgUserContext } from 'contexts';
// Utils, data sources, and entities
import { FadeInTransition } from 'utils/animate';
import { skipNullArr } from 'utils/nodash';
import type { RouterHistoryT } from 'sharedTypes/reactRouter';

import { navigatorDefaultUrl } from 'layouts/PrimaryLayout/PrimaryNavbar';
import RequestAttributionSelection from 'views/BdRequests/components/RequestAttributionSelection';
import ConversationSettingsAlert from 'views/BdRequests/NewBdRequest/ConversationSettingsAlert';
import ConversationVisibility from 'views/BdRequests/NewBdRequest/ConversationVisibility';
import FormGroup from 'views/BdRequests/NewBdRequest/FormGroup';
import PartnerAccountSelection from 'views/BdRequests/NewBdRequest/PartnerAccountSelection';
import ToParticipantUserSearch from 'views/BdRequests/NewBdRequest/ToParticipantUserSearch';
import CompanyLogo from 'components/CompanyLogo';
import FieldErrors from 'components/FieldErrors';
// Components
import LoadingRing from 'components/LoadingRing';
import OpportunitySearch from 'components/OpportunitySearch';
import PersonAvatar from 'components/PersonAvatar';
import Unisearchal from 'components/Unisearchal';

import RequestMessageInputArea from '../AddBdRequestComment/InputArea';

import BdRequestMini from './BdRequestMini';
import PreviousConversations from './PreviousConversations';

const makeBlankFormData = (isShared: boolean = false, logActivity: boolean = false) => ({
  accountId: null,
  partnerId: null,
  opportunityId: '',
  summary: '',
  status: statusChoices.REQUESTED,
  outcome: '',
  urgency: null,
  attributeAs: null,
  isShared,
  messageText: '',
  partnerAccountId: null,
});

type ViewParams = {
  accountSlug: string,
  partnerSlug: string,
  partnerAccountId: string,
  source: string,
  toOrgUserId: string,
  xbRecordId: string,
  xbPartnerOrgId: string,
  xbPartnerRecordId: string,
};

export type MessageUpdate = {
  messageText: string,
  createdRequestId: ?string,
  toOrgUserIds: string[],
  collapsed: boolean,
};

type Message = ViewParams & MessageUpdate;

type Props = {
  history: RouterHistoryT,
  canManage: boolean,
  org: Org,
  logActivity?: boolean,
  inMessaging?: boolean,
  message?: Message,
  onUpdate?: (update: MessageUpdate) => void,
  discard?: () => void,
};

type State = {
  // Form fields
  accountId: ?string,
  partnerId: ?string,
  opportunityId: ?string,
  summary: ?string,
  status: ?string,
  outcome: ?string,
  urgency: ?string,
  attributeAs: ?string,
  isShared: boolean,
  messageText: string,
  partnerAccountId: ?string,
  // Rest
  loadingDefaults: boolean,
  submitting: boolean,
  createdRequestId: ?string,
  gotoRequest: boolean,
  accountDefault: SearchIndexItem[],
  partnerDefault: SearchIndexItem[],
  toOrgUserDefault?: OrgUserNameOnly,
  selectedParticipants: OrgUserNameOnly[],
  hasPartnerAccount: boolean,
  account: ?SearchIndexItem,
  lockedFields: string[],
  partner: ?Partner,
  source?: ?string,
  mentionableUsers?: OrgUserNameOnly[],
  errors: ?{ [string]: any },
  messageTextDirty: boolean,
  partnerAccount?: ?PartnerAccount,
  collapsed: boolean,
  failedToLoadDefaults: boolean,
};

class NewBdRequestForm extends React.Component<Props, State> {
  state = {
    loadingDefaults: false,
    submitting: false,
    createdRequestId: null,
    gotoRequest: !this.props.inMessaging,
    partner: null,
    accountDefault: [],
    partnerDefault: [],
    ...makeBlankFormData(this.props.inMessaging, this.props.logActivity),
    account: null,
    lockedFields: [],
    selectedParticipants: [],
    hasPartnerAccount: false,
    errors: null,
    messageTextDirty: false,
    collapsed: !this.props.inMessaging || !!this.props.message?.collapsed,
    failedToLoadDefaults: false,
  };

  inputRefs: { account?: WrappedTypeahead, partner?: WrappedTypeahead } = {};

  componentDidMount() {
    // Set default account or partner from url params
    const { accountSlug, partnerSlug, xbRecordId, xbPartnerOrgId } = this.getQueryParams();
    const hasDefaults = !!skipNullArr([accountSlug, partnerSlug, xbRecordId, xbPartnerOrgId])
      .length;
    if (hasDefaults) {
      this.setState({ loadingDefaults: hasDefaults }, () => {
        this.updateFromQuery();
      });
    }
  }

  componentDidUpdate(prevProps) {
    // FIXME We're handling this terribly; one fixed brittle case because there is no diff in the location
    //  query between prevProps and props for some unknown reason.
    //  So for now, this ONLY handles going from having defaults to clearing them out.
    const search = get(this.props, 'history.location.search', '');
    const { lockedFields } = this.state;
    if (!search && lockedFields && lockedFields.length && !this.props.inMessaging) {
      this.setState({
        lockedFields: [],
        partnerAccountId: null,
        selectedParticipants: [],
        isShared: true,
      });
    }
    // Update drafts.
    if (this.props.inMessaging) {
      this.props.onUpdate({
        messageText: this.state.messageText,
        toOrgUserIds: this.state.selectedParticipants.map((x) => x.id),
        createdRequestId: this.state.createdRequestId,
        collapsed: this.state.collapsed,
      });
    }
  }

  getQueryParams = (): ViewParams | Message => {
    const { inMessaging, message } = this.props;
    const search = get(this.props, 'history.location.search', '');
    const query = queryString.parse(search, { ignoreQueryPrefix: true });
    const {
      account: accountSlug,
      xbRecordId,
      xbPartnerOrgId,
      xbPartnerRecordId,
      partner: partnerSlug,
      partnerAccountId,
      source,
      to: toOrgUserId,
    } = query;

    if (inMessaging && message) {
      return message;
    }
    return {
      accountSlug,
      partnerSlug,
      partnerAccountId,
      source,
      toOrgUserId,
      xbRecordId,
      xbPartnerOrgId,
      xbPartnerRecordId,
    };
  };

  swapXbIds = async (): Promise<{
    accountSlug: ?string,
    partnerSlug: ?string,
    partnerAccountId: ?string,
  }> => {
    const {
      accountSlug: qAccountSlug,
      partnerSlug: qPartnerSlug,
      partnerAccountId: qPartnerAccountId,
      xbRecordId,
      xbPartnerOrgId,
      xbPartnerRecordId,
    } = this.getQueryParams();

    let partnerAccountId = null;
    let partnerSlug;
    let accountSlug;
    // If we have params with XB IDs we need to convert them to our IDs
    if (xbRecordId) {
      const result = await fetchAccountIdsByXBRecordId(xbRecordId).catch(() => {
        this.setState({ loadingDefaults: false, failedToLoadDefaults: true });
      });
      accountSlug = result?.slug;
    } else {
      accountSlug = qAccountSlug;
    }
    if (xbPartnerOrgId) {
      const result = await fetchPartnerIdsByXBRecordId(xbPartnerOrgId).catch(() => {
        this.setState({ loadingDefaults: false, failedToLoadDefaults: true });
      });
      partnerSlug = result?.slug;
    } else {
      partnerSlug = qPartnerSlug;
    }
    if (xbPartnerRecordId && partnerSlug && accountSlug) {
      const result = await listPartnerMappedAccountsByAccount(
        partnerSlug,
        accountSlug,
        null,
        xbPartnerRecordId
      );
      if (result.length > 0) {
        partnerAccountId = result[0].partnerAccount?.id;
      }
    } else {
      partnerAccountId = qPartnerAccountId;
    }
    return {
      partnerAccountId,
      accountSlug,
      partnerSlug,
    };
  };

  updateFromQuery = async () => {
    const { org, inMessaging } = this.props;
    const participantsToAdd = [];
    const {
      source,
      toOrgUserId,
      // Messaging params
      messageText,
      toOrgUserIds,
    } = this.getQueryParams();
    const { accountSlug, partnerSlug, partnerAccountId } = await this.swapXbIds();
    if (partnerAccountId) {
      // FIXME We should validate the partner account id here
      this.setIsShared(true);
      this.setState({ lockedFields: ['partnerAccountId'], source });
    }
    if (inMessaging && messageText) {
      this.setState({ messageText, messageTextDirty: true });
    }
    const { accountDefault, partnerDefault, toOrgUserDefault } = this.state;
    const getDefaultVals = (entityKind, entity, existingState) => {
      if (!entity) {
        return {};
      }
      const { id, name, slug } = entity;
      const item = { id, kind: entityKind, name, slug };
      // Set defaultSelected and default values
      const fieldName = `${entityKind}Id`;
      const fieldState = this.onUnisearchalChangeNext(fieldName, item);
      const { lockedFields, ...rest } = existingState;
      return {
        ...rest,
        [`${entityKind}Default`]: [item],
        lockedFields: [...(lockedFields || []), fieldName],
        ...fieldState,
      };
    };
    // Set defaultSelected and default values
    const promises = [];

    if (accountSlug && !accountDefault.length) {
      // fetch the account, load it as the default
      promises.push(
        fetchAccountBySlug(accountSlug).then((account) => {
          this.setState((state) => ({ ...getDefaultVals('account', account, state) }));
        })
      );
    }

    if (partnerSlug && !partnerDefault.length) {
      // Load the partner, so it as partnerDefault and as form value. Should disable when loading.
      promises.push(
        fetchPartnerBySlug(partnerSlug).then(async (partner) => {
          const isDefaultShared = !!partner && !!partnerAccountId;
          if (partnerAccountId) {
            const { results } = await bdRequestMentionableUsers(
              null,
              null,
              partnerAccountId,
              partner.id
            );
            this.setState({ mentionableUsers: results }, () => {
              // We need the mentionableUsers to be known before continuing
              participantsToAdd.push(
                OrgUserNameOnly.fromOrgUser(partner.bdManager, org.name, org.url)
              );
              if (partner.bdManagerAtPartner) {
                participantsToAdd.push(
                  OrgUserNameOnly.fromOrgUser(
                    partner.bdManagerAtPartner,
                    partner.name,
                    partner.domain
                  )
                );
              }
            });
          }
          this.setIsShared(isDefaultShared);
          this.setState({ partner, partnerAccountId });
          this.setState((state) => ({ ...getDefaultVals('partner', partner, state) }));
        })
      );
    }

    const fetchParticipant = (id: string) => {
      promises.push(
        fetchNetworkProfile(id)
          .then(OrgUserNameOnly.fromNetworkProfile)
          .then((_toOrgUserDefault: OrgUserNameOnly) => {
            this.setState({ toOrgUserDefault: _toOrgUserDefault });
            participantsToAdd.push(_toOrgUserDefault);
          })
          .catch((e) => {
            const { response } = e;
            if (response?.status !== 404) {
              throw e;
            }
          })
      );
    };

    if (inMessaging && toOrgUserIds && !toOrgUserDefault) {
      toOrgUserIds.forEach((id) => fetchParticipant(id));
    } else if (toOrgUserId && !toOrgUserDefault) {
      fetchParticipant(toOrgUserId);
    }

    return Promise.all(promises)
      .then(() => {
        // Finished loading the defaults
        participantsToAdd.map((p) => this.addParticipant(p));
        this.setState({ loadingDefaults: false });
        this.setDefaultMessage();
      })
      .catch((e) => {
        this.setState({ loadingDefaults: false, failedToLoadDefaults: true });
      });
  };

  buildParticipant = (
    orguser: OrgUserNameOnly | OrgUser,
    givenOrgName: ?string = null,
    givenOrgDomain: ?string = null
  ): ?OrgUserNameOnly => {
    if (!orguser) {
      return null;
    }
    let participant = orguser;
    if (!(participant instanceof OrgUserNameOnly)) {
      const { org } = this.props;
      const orgName = givenOrgName || org.name;
      const orgDomain = givenOrgDomain || org.url;
      participant = OrgUserNameOnly.fromOrgUser(orguser, orgName, orgDomain);
    }
    return participant;
  };

  addParticipant = (
    orguser: OrgUserNameOnly,
    givenOrgName: ?string = null,
    givenOrgDomain: ?string = null
  ) => {
    const participant = this.buildParticipant(orguser, givenOrgName, givenOrgDomain);
    if (!participant) {
      return;
    }
    this.setSelectedParticipants([participant]);
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { submitting } = this.state;
    if (submitting) {
      return;
    }
    this.setState({ submitting: true }, this.doCreateBdRequest);
  };

  showAttribution = () => {
    const { opportunityId, partnerId, status } = this.state;
    return opportunityId && partnerId && status === statusChoices.COMPLETED;
  };

  doCreateBdRequest = () => {
    const {
      accountId,
      partnerId,
      opportunityId,
      summary,
      urgency,
      gotoRequest,
      status,
      attributeAs,
      isShared,
      messageText,
      partnerAccountId,
      source,
      selectedParticipants,
      partner,
    } = this.state;
    const { history } = this.props;
    const actualUrgency = urgency;
    const actualOutcome = null;
    let actualAttribution = attributeAs;
    let actualSummary = summary;
    let message = null;
    if (status !== statusChoices.COMPLETED) {
      actualAttribution = null;
    }
    if (messageText) {
      message = { isShared, text: messageText };
      actualSummary = null;
    }
    const toOrgUserIds = selectedParticipants ? selectedParticipants.map(({ id }) => id) : null;

    createBdRequest(
      accountId,
      partnerId,
      opportunityId,
      actualSummary,
      status,
      actualOutcome,
      actualUrgency,
      actualAttribution,
      message,
      partnerAccountId,
      source,
      toOrgUserIds,
      this.getQueryParams().toOrgUserId || undefined
    )
      .then(({ id }) => {
        itly.userClicksMessage({
          partner_org_id: partner.partnerOrg.id,
          in_slack: false,
          organization_id: this.props.org.id,
          type: this.state.isShared ? 'external' : 'internal',
          context: {
            text: messageText,
            opportunity_id: opportunityId,
            partner_account_id: partnerAccountId,
            source,
            account_id: accountId,
          },
          status,
        });
        // Should redirect to the new bdrequest
        if (gotoRequest) {
          const nextUrl = `/requests/${id}`;
          history.push(nextUrl);
        } else {
          this.setState({
            submitting: false,
            createdRequestId: id,
            ...makeBlankFormData(this.props.inMessaging, this.props.logActivity),
            status,
          });
          // Clear the typeaheads
          // eslint-disable-next-line no-underscore-dangle
          Object.values(this.inputRefs).map((inputRef) => inputRef._instance.clear());
          if (!this.props.inMessaging) {
            setTimeout(() => {
              this.setState({ createdRequestId: null });
            }, 10000);
          }
        }
      })
      .catch((errors) => {
        this.setState({ submitting: false, errors });
      });
  };

  onChange = (event: SyntheticEvent<HTMLInputElement | HTMLButtonElement>) => {
    const { currentTarget } = event;
    let { value } = currentTarget;
    if (currentTarget instanceof HTMLInputElement && currentTarget.type === 'checkbox') {
      value = currentTarget.checked;
    }
    this.setState({ [currentTarget.name]: value });
  };

  onSelectOpportunity = (opportunity: Opportunity) => {
    this.setState({ opportunityId: opportunity?.id });
  };

  onSelectAttributeAs = (attributeAs: string) => {
    this.setState({ attributeAs });
  };

  fetchPartner = (result: ?SearchIndexItem) => {
    if (result && result.slug) {
      fetchPartnerBySlug(result.slug).then((partner) => this.setState({ partner }));
    } else {
      this.setState({ partner: null });
    }
  };

  onUnisearchalChangeNext = (stateKey: string, val: ?SearchIndexItem) => {
    const value = val ? val.id : null;
    const nextState = { [stateKey]: value };
    if (stateKey === 'accountId') {
      nextState.account = val;
    } else if (stateKey === 'partnerId') {
      this.fetchPartner(val);
    }
    return nextState;
  };

  onUnisearchalChange =
    (stateKey: string): ((?SearchIndexItem) => void) =>
    (val: ?SearchIndexItem = null) => {
      this.setState({ ...this.onUnisearchalChangeNext(stateKey, val) });
    };

  onPartnerAccountSelected = (partnerAccount: PartnerAccount) => {
    this.setState({ partnerAccountId: partnerAccount?.id, partnerAccount }, () => {
      this.setIsShared(!!partnerAccount);
    });
  };

  onHasPartnerAccountResults = (hasPartnerAccount: boolean) => {
    this.setState({ hasPartnerAccount });
  };

  onChangeRecipients = (recipients: OrgUserNameOnly[]) => {
    // Check if it's still shared
    this.setSelectedParticipants(recipients, true);
  };

  setSelectedParticipants = (participants: OrgUserNameOnly[], replace: boolean = false) => {
    const { org } = this.props;
    const { mentionableUsers, partner } = this.state;
    const mentionableIds = mentionableUsers ? mentionableUsers.map((o) => o.id) : [];
    let selectedParticipants = filter(
      participants.map((o: OrgUserNameOnly | OrgUser) =>
        this.buildParticipant(o, o.orgName || o.org?.name, o.orgDomain || o.org?.domain)
      ),
      (o) => o.orgName === org.name || mentionableIds.includes(o.id)
    );
    // FIXME Caution, using organization name, rather than ID, seem like it could be prone to bugs
    const includesPartner = filter(selectedParticipants, (o) => o.orgName !== org.name).length;
    if (includesPartner && partner?.bdManagerAtPartner) {
      // Ensure partner manager exists, will get de-duped below
      selectedParticipants.push(
        OrgUserNameOnly.fromOrgUser(partner.bdManagerAtPartner, partner.name, partner.domain)
      );
    }
    if (!this.isSharedConversationCapable()) {
      // User cannot start a shared conversation, filter out the participants
      selectedParticipants = filter(selectedParticipants, (o) => o.orgName === org.name);
    }
    this.setIsShared(!!includesPartner);
    this.setState(({ selectedParticipants: existingRecipients }) => {
      let nextRecipients = selectedParticipants;
      if (!replace) {
        nextRecipients = [...existingRecipients, ...selectedParticipants];
      }
      return { selectedParticipants: uniqBy(nextRecipients, 'id') };
    });
  };

  isSharedConversationCapable = () => {
    const { logActivity } = this.props;
    const { partner } = this.state;
    return !logActivity && !!partner;
  };

  setIsShared = (isShared: boolean) => {
    if (this.isSharedConversationCapable()) {
      this.setState({ isShared });
    }
    this.setDefaultMessage();
  };

  setDefaultMessage = () => {
    // For new messages (not log activity) set a default message to the partner
    setTimeout(() => {
      // Why setTimeout? This relies on misc state, and ensures all previous async state actions are picked up first
      // Generally, I'd avoid this. However, it's called in initial loading where state is set in multiple places
      const { logActivity } = this.props;
      const {
        isShared,
        toOrgUserDefault,
        selectedParticipants,
        messageTextDirty,
        account,
        partner,
        partnerAccount,
      } = this.state;
      let messageText = '';
      const accountName = partnerAccount?.name || account?.name;

      // Only set the default message if the user hasn't touched the message, isn't log activity, and has all info
      if (logActivity || messageTextDirty || !accountName || !partner) return;

      if (!isShared && partner && partner.bdManager && account) {
        // Internal conversation default message
        messageText = `Hi ${partner.bdManager.firstName}! Can you help me get connected to ${partner.name} on ${account.name}?`;
      } else {
        // Shared conversation default message
        const hasToOrguser =
          toOrgUserDefault && some(selectedParticipants, (o) => o.id === toOrgUserDefault.id);
        const toName = hasToOrguser ? toOrgUserDefault.firstName : `${partner.name} team`;
        messageText = `Hi ${toName}, \nCan we discuss ${accountName}?`;
      }

      this.setState({ messageText });
    });
  };

  setMessage = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ messageTextDirty: true, messageText: event.target.value });
  };

  setCollapsed = (collapsed: boolean) => {
    this.setState({ collapsed });
  };

  render() {
    const { logActivity, canManage, org, inMessaging, discard } = this.props;
    const {
      submitting,
      createdRequestId,
      account,
      gotoRequest,
      opportunityId,
      status,
      accountDefault,
      partnerDefault,
      loadingDefaults,
      isShared,
      messageText,
      partnerAccountId,
      lockedFields,
      partner,
      errors,
      selectedParticipants,
      hasPartnerAccount,
      collapsed,
      failedToLoadDefaults,
    } = this.state;
    const showAttribution = this.showAttribution();

    const attributionEnabled = org && org.settings.attributionEnabled;

    const selectedCompleted = status === statusChoices.COMPLETED;

    let submitBtnTxt = '';
    if (selectedCompleted) {
      submitBtnTxt = 'Create Activity';
    } else {
      submitBtnTxt = 'Start conversation';
    }

    const showToField = !logActivity && !!selectedParticipants?.length;

    const RequestForm = (
      <div className={inMessaging ? 'overflow-auto' : ''}>
        {this.isSharedConversationCapable() && (
          <ConversationSettingsAlert partner={partner} canManage={canManage} />
        )}
        <FieldErrors errors={!!errors && errors.nonFieldErrors} />
        <form
          onSubmit={this.onSubmit}
          className={classNames(
            'NewRequestForm',
            logActivity ? 'NewBdRequest__log-activity' : 'NewBdRequest__message'
          )}
        >
          {/*  {logActivity && (
            <LogActivitySelectionButtons
              onChange={this.onChange}
              selectedCompleted={selectedCompleted}
            />
          )} */}
          {showToField && (
            <FormGroup errors={errors?.addParticipantIds} label="To:">
              <ToParticipantUserSearch
                partner={partner}
                partnerAccountId={partnerAccountId}
                onChange={this.onChangeRecipients}
                selectedParticipants={selectedParticipants}
              />
            </FormGroup>
          )}
          <FormGroup errors={errors?.partnerId} label="Partner:">
            {partner && lockedFields.includes('partnerId') ? (
              <div className="d-flex align-items-center">
                <CompanyLogo
                  domain={partner?.domain}
                  name={partner.name}
                  logoUrl={partner.partnerOrg.logoUrl}
                  size={16}
                  className="mr-2"
                />
                {partner.name}
              </div>
            ) : (
              <Unisearchal
                placeholder="Search for a partner..."
                onChange={this.onUnisearchalChange('partnerId')}
                bsSize="sm"
                onClear={this.onUnisearchalChange('partnerId')}
                entityKind="partner"
                inputRef={(ref) => {
                  this.inputRefs.partner = ref;
                }}
                defaultSelected={partnerDefault}
                stripUnisearchalClassName
                hideClearButton
                loadInitial
                disabled={lockedFields.includes('partnerId')}
              />
            )}
          </FormGroup>
          {!inMessaging && (
            <FormGroup errors={errors?.accountId} label="Account:">
              {accountDefault?.length && lockedFields.includes('accountId') ? (
                accountDefault[0].name
              ) : (
                <Unisearchal
                  placeholder="Search for an account..."
                  onChange={this.onUnisearchalChange('accountId')}
                  bsSize="sm"
                  onClear={this.onUnisearchalChange('accountId')}
                  entityKind="account"
                  inputRef={(ref) => {
                    this.inputRefs.account = ref;
                  }}
                  defaultSelected={accountDefault}
                  stripUnisearchalClassName
                  disabled={lockedFields.includes('accountId')}
                  hideClearButton
                />
              )}
            </FormGroup>
          )}
          {this.isSharedConversationCapable() && (
            <div>
              <PartnerAccountSelection
                partnerAccountId={partnerAccountId}
                isEditable={!lockedFields.includes('partnerAccountId')}
                isVisible={hasPartnerAccount}
                onHasResults={this.onHasPartnerAccountResults}
                onPartnerAccountChange={this.onPartnerAccountSelected}
                partnerSlug={partner ? partner.slug : null}
                accountSlug={account ? account.slug : null}
              />
            </div>
          )}
          {!showToField && (
            <FormGroup
              errors={errors?.opportunityId}
              label="Opportunity:"
              formText="Which opportunity is related to this partner interaction?"
            >
              <OpportunitySearch
                onChange={this.onSelectOpportunity}
                account={account}
                changeOnClear
              />
            </FormGroup>
          )}
          {selectedCompleted && attributionEnabled && (
            <FormGroup
              errors={errors?.attributeAs}
              label="Attribution:"
              formText={
                <>
                  Did the partner help <strong>Influence stage movement</strong>,{' '}
                  <strong>Source</strong> the deal, or neither?
                </>
              }
            >
              <RequestAttributionSelection
                request={{ opportunity: { id: opportunityId } }}
                onChange={this.onSelectAttributeAs}
                disabled={!showAttribution}
              />
            </FormGroup>
          )}
          <div className={classNames('form-group py-3 my-0', !logActivity && 'mx-4')}>
            <ConversationVisibility org={org} isShared={isShared} partner={partner} />
            <RequestMessageInputArea
              isShared={isShared}
              bdRequestShared={false}
              disableSharedSelector
              newRequest
              text={messageText}
              partnerAccountId={partnerAccountId}
              partner={partner}
              accountSlug={account && account.slug}
              onPartnerAccountChange={this.onPartnerAccountSelected}
              onChangeText={this.setMessage}
              onChangeIsShared={(isSharedVal) => {
                this.setState({ isShared: isSharedVal });
              }}
              isEditable={!lockedFields.includes('partnerAccountId')}
              errors={errors && errors.message}
              submitButton={
                <button className="AddRequestSubmitButton" disabled={submitting} type="submit">
                  <FontAwesomeIcon icon="paper-plane" /> {submitBtnTxt}
                </button>
              }
            />
            <FieldErrors errors={errors?.message?.text} />
          </div>
          <TransitionGroup>
            {createdRequestId && (
              <FadeInTransition>
                <div className="alert alert-success mb-2">
                  Request Created!{' '}
                  <a href={`/requests/${createdRequestId}`} target="_blank">
                    &raquo; View it
                  </a>
                </div>
              </FadeInTransition>
            )}
          </TransitionGroup>
          {logActivity && (
            <div className="form-group">
              <div className={classNames('form-group form-check', !logActivity && 'mx-4')}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="gotoRequest"
                  name="gotoRequest"
                  checked={gotoRequest}
                  onChange={this.onChange}
                />
                <label
                  className="form-check-label"
                  style={{ fontFamily: 'helvetica', textTransform: 'unset' }}
                  htmlFor="gotoRequest"
                >
                  Go to Request after creation
                </label>
              </div>
            </div>
          )}
        </form>
      </div>
    );

    if (inMessaging) {
      const title = `Request${partner ? ` to ${partner.name}` : ''}${
        account ? ` about ${account.name}` : ''
      }`;
      const participant = selectedParticipants[0];
      const messaged = !!createdRequestId;
      const discardMessage = (e) => {
        e.stopPropagation();
        if (messaged) {
          discard && discard();
          // eslint-disable-next-line no-alert, no-restricted-globals
        } else if (confirm('Are you sure you want to discard this message?')) {
          discard && discard();
        }
      };
      return collapsed ? (
        <div
          tabIndex={0}
          onKeyPress={() => this.setCollapsed(false)}
          role="button"
          className="MessageCollapsed cursor-pointer d-flex flex-row align-items-center gap-10"
          onClick={() => this.setCollapsed(false)}
        >
          {loadingDefaults ? (
            <LoadingRing maxWidth="20px" className="m-auto" />
          ) : (
            <>
              <FontAwesomeIcon
                className="text-primary"
                icon={messaged ? 'hashtag' : 'pencil-alt'}
              />
              <div className="d-flex flex-column flex-fill" style={{ width: 0 }}>
                <span className="normal-text bold gray-700 text-truncate">{title}</span>
                {showToField && participant && (
                  <div className="d-flex flex-row align-items-center gap-5">
                    <PersonAvatar
                      firstName={participant.firstName}
                      lastName={participant.lastName}
                      org={{
                        domain: participant.orgDomain,
                        name: participant.orgName,
                        logoUrl: participant.orgLogoUrl,
                      }}
                      size={16}
                      noMargin
                    />
                    <span className="small-text semi-bold gray-700 text-truncate">
                      {participant.fullName}
                    </span>
                  </div>
                )}
              </div>
              <FontAwesomeIcon
                className="gray-600 p-2"
                icon="xmark"
                title="Discard"
                onClick={discardMessage}
              />
            </>
          )}
        </div>
      ) : (
        <div className="MessageExpanded d-flex flex-column">
          <div
            className="MessageHeader cursor-pointer d-flex flex-row align-items-center gap-10"
            tabIndex={0}
            role="button"
            onClick={() => this.setCollapsed(true)}
            onKeyPress={() => this.setCollapsed(true)}
          >
            <div
              className="d-flex flex-row align-items-center gap-10 flex-fill"
              style={{ width: 0 }}
            >
              <FontAwesomeIcon
                className="text-primary"
                icon={messaged ? 'hashtag' : 'pencil-alt'}
              />
              {!loadingDefaults && (
                <span className="normal-text bold gray-700 text-truncate">{title}</span>
              )}
            </div>
            <PreviousConversations request={{ account, partner, id: createdRequestId }} />
            {messaged && createdRequestId && (
              <Link to={`/requests/${createdRequestId}`} onClick={discardMessage}>
                <FontAwesomeIcon
                  className="gray-600"
                  icon="arrow-up-right-and-arrow-down-left-from-center"
                />
              </Link>
            )}
            <FontAwesomeIcon
              className="gray-600 p-2"
              icon="xmark"
              title="Discard"
              onClick={discardMessage}
            />
          </div>
          {loadingDefaults ? (
            <LoadingRing maxWidth="50px" className="m-auto" />
          ) : (
            <>
              {messaged && createdRequestId ? (
                <BdRequestMini requestId={createdRequestId} org={org} />
              ) : (
                RequestForm
              )}
            </>
          )}
        </div>
      );
    }

    return loadingDefaults ? (
      <LoadingRing />
    ) : (
      <>
        {failedToLoadDefaults ? (
          <div className="d-flex flex-column gap-5 align-items-center p-2">
            <FontAwesomeIcon icon="chain-broken" size="2x" />
            <h3 className="m-0 mt-2 bold gray-700">The mapping doesn&apos;t exist</h3>
            <div className="large-text semi-bold gray-600 text-center">
              The mapping you are trying to message your partner doesn&apos;t exist in Crossbeam for
              Sales.
            </div>
            <Link to={navigatorDefaultUrl()} className="mt-4 btn btn-primary">
              Go to Navigator
            </Link>
          </div>
        ) : (
          RequestForm
        )}
      </>
    );
  }
}

NewBdRequestForm.defaultProps = {
  logActivity: false,
  inMessaging: false,
  message: null,
  onUpdate: () => {},
  discard: () => {},
};

function NewBdRequestFormContainer(props) {
  return (
    <OrgUserContext.Consumer>
      {({ orguser, org }) => (
        <NewBdRequestForm {...props} canManage={orguser.canManage} org={org} />
      )}
    </OrgUserContext.Consumer>
  );
}

export default NewBdRequestFormContainer;
