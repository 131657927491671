/* eslint-disable max-classes-per-file */
// @flow

import * as React from 'react';
import { get } from 'lodash';

import { getStatusComponent, getStatusStr } from 'data/entities/status';
import { newObjectWithApiValues } from 'data/entities/utils';
import { camelizeKeys } from 'utils/nodash';

import { PartnerInviteSent } from './invites';
import MinimalUser from './minimalUser';
import PartnerOrg from './partnerOrg';
import { SalesCollateralLink } from './salesCollateralLink';
import { AccountSignals } from './signal';
import { SlackChannel } from './slack';

//--
// Partner Orgs
//--

class VisibilitySettings {
  accountStatusVisible: boolean;

  accountOwnerVisible: boolean;

  contactsVisible: boolean;

  static fromApi(data: any): VisibilitySettings {
    return newObjectWithApiValues(VisibilitySettings, data);
  }
}

class ConversationSettings {
  enabledDirectConversations: boolean;

  static fromApi(data: any): ConversationSettings {
    return newObjectWithApiValues(ConversationSettings, data);
  }
}

const ExclusivePartnerFilters = {
  MAPPING: 'mapping',
  IS_CONNECTED: 'is_connected',
  PENDING_INVITES: 'pending_invites',
  NOT_INVITED: 'not_invited',
  FINISH_CONNECTING: 'finish_connecting',
  SYNC_ENABLED: 'sync_enabled',
  SYNC_DISABLED: 'sync_disabled',
};

type ExclusivePartnerFilter = $Keys<typeof ExclusivePartnerFilters>;

const OverlapSharingSettings = {
  ALL: 'ALL',
  MANUAL: 'MANUAL',
  SEGMENT: 'SEGMENT',
};

type OverlapSharingSetting = $Keys<typeof OverlapSharingSettings>;

class PartnerSettings {
  enableAccountMapping: boolean;

  overlapSharingSetting: OverlapSharingSetting;

  visibilitySettings: VisibilitySettings;

  conversationSettings: ConversationSettings;

  requestsWorkflowSettings: {
    allowCustomQuestions: boolean,
  };

  get isManualMapping(): boolean {
    return this.overlapSharingSetting === OverlapSharingSettings.MANUAL;
  }

  static fromApi(data: any): PartnerSettings {
    const ps: PartnerSettings = newObjectWithApiValues(PartnerSettings, data);
    ps.visibilitySettings = VisibilitySettings.fromApi(ps.visibilitySettings);
    ps.conversationSettings = ConversationSettings.fromApi(ps.conversationSettings);
    ps.requestsWorkflowSettings = {
      allowCustomQuestions: data.requests_workflow_settings.allow_custom_questions,
    };
    return ps;
  }
}

class SettingsByPartner extends PartnerSettings {}

class PartnerOrgUser extends MinimalUser {
  id: string;

  orgId: string;

  firstName: string;

  lastName: string;

  email: string;

  title: string;

  avatarImage: string;

  orgName: string;

  orgDomain: string;

  orgLogoUrl: string;

  static fromApi: (data: any) => PartnerOrgUser;
}

PartnerOrgUser.fromApi = (data: any): PartnerOrgUser => {
  const pou = new PartnerOrgUser();
  pou.id = data.id;
  pou.orgId = data.org_id;
  pou.firstName = data.first_name;
  pou.lastName = data.last_name;
  pou.title = data.title;
  pou.avatarImage = data.avatar_image;
  pou.email = data.email;
  pou.orgName = data.org_name;
  pou.orgDomain = data.org_domain;
  pou.orgLogoUrl = data.org_logo_url;
  return pou;
};

class PartnerBasic {
  id: string;

  orgId: string;

  name: string;

  domain: string;

  description: string;

  salesCollateralLinks: SalesCollateralLink[];

  bdManagerAtPartner: PartnerOrgUser;

  slug: string;

  isConnected: boolean;

  logoUrl: ?string;

  slackChannel: ?SlackChannel;

  canBeAskedCustomQuestions: boolean;

  get mappingUrl(): string {
    return `/admin/account-mapping?partner=${this.slug}`;
  }

  static fromApi: (data: any) => PartnerBasic;
}
PartnerBasic.fromApi = (data: any): PartnerBasic => {
  const p = new PartnerBasic();
  p.id = data.id;
  p.orgId = data.partner_org_id;
  p.domain = data.partner_org_domain;
  p.name = data.partner_org_name;
  p.description = data.description;
  p.logoUrl = data.partner_org_logo_url;
  p.slug = data.slug;
  p.isConnected = data.is_connected;
  p.slackChannel = data.slack_channel && SlackChannel.fromApi(data.slack_channel);
  p.bdManagerAtPartner =
    data.bd_manager_at_partner && PartnerOrgUser.fromApi(data.bd_manager_at_partner);
  p.salesCollateralLinks =
    data.sales_collateral_links && data.sales_collateral_links.map(SalesCollateralLink.fromApi);
  p.canBeAskedCustomQuestions = data.can_be_asked_custom_questions;
  return p;
};

class Partner {
  id: string;

  slug: string;

  name: string;

  description: ?string;

  orgDescription: ?string;

  partnerOrg: PartnerOrg;

  partnerConnectedOrg: PartnerOrg;

  crossbeamPartnerIntegrationSettings: ?{
    lastSyncAt: ?Date,
    partnerOrgId: string,
    syncEnabled: boolean,
  };

  bdManager: PartnerOrgUser | null;

  bdManagerAtPartner: PartnerOrgUser;

  pendingRequestsCount: number;

  settings: PartnerSettings;

  settingsByPartner: ?SettingsByPartner;

  invite: ?PartnerInviteSent;

  domain: string;

  isConnectedB: ?boolean;

  overlapSharingSegments: ?(string[]);

  forOrgProvider: ?string;

  logoUrl: ?string;

  slackChannel: ?SlackChannel;

  canBeAskedCustomQuestions: boolean;

  slackChannel: ?SlackChannel;

  salesCollateralLinks: ?(SalesCollateralLink[]);

  isDeleted: ?Boolean;

  get mappingUrl(): string {
    return `/admin/account-mapping?partner=${this.slug}`;
  }

  static fromApi: (data: any) => Partner;

  get isConnected(): boolean {
    if (this.isConnectedB != null) return this.isConnectedB;
    const { partnerConnectedOrg } = this;
    return partnerConnectedOrg && !partnerConnectedOrg.pseudoOrgForPartnerId;
  }

  get isSynced(): boolean {
    const { crossbeamPartnerIntegrationSettings } = this;
    return crossbeamPartnerIntegrationSettings && crossbeamPartnerIntegrationSettings.syncEnabled;
  }

  get canFilterPartnerAccountStatus(): boolean {
    return get(this, 'settingsByPartner.visibilitySettings.accountStatusVisible', false);
  }

  get canFilterPartnerAccountOwner(): boolean {
    return get(this, 'settingsByPartner.visibilitySettings.accountOwnerVisible', false);
  }

  get canFilterPartnerAccounts(): boolean {
    return this.canFilterPartnerAccountStatus && this.canFilterPartnerAccountOwner;
  }
}

Partner.fromApi = (data: any): Partner => {
  const p = new Partner();
  p.id = data.id;
  p.slug = data.slug;
  p.name = data.partner_org_name;
  p.description = data.description;
  p.orgDescription = data.partner_org_description;
  p.overlapSharingSegments = data.overlap_sharing_segments;
  p.partnerOrg = data.partner_org && PartnerOrg.fromApi(data.partner_org, p.slug);
  p.partnerConnectedOrg =
    data.partner_connected_org && PartnerOrg.fromApi(data.partner_connected_org, p.slug);
  p.bdManager = data.bd_manager && PartnerOrgUser.fromApi(data.bd_manager);
  p.bdManagerAtPartner =
    data.bd_manager_at_partner && PartnerOrgUser.fromApi(data.bd_manager_at_partner);
  p.pendingRequestsCount = data.pending_requests_count;
  p.settings = PartnerSettings.fromApi(data.settings);
  p.settingsByPartner = data.settings_by_partner
    ? SettingsByPartner.fromApi(data.settings_by_partner)
    : null;
  p.invite = data.invite ? PartnerInviteSent.fromApi(data.invite) : null;
  p.crossbeamPartnerIntegrationSettings = data.crossbeam_partner_integration_settings && {
    lastSyncAt:
      data.crossbeam_partner_integration_settings.last_sync_at &&
      new Date(data.crossbeam_partner_integration_settings.last_sync_at),
    partnerOrgId: data.crossbeam_partner_integration_settings.partner_org_id,
    syncEnabled: data.crossbeam_partner_integration_settings.sync_enabled,
  };
  p.slackChannel = data.slack_channel && SlackChannel.fromApi(data.slack_channel);
  p.domain = data.domain;
  p.isConnectedB = data.is_connected;
  p.forOrgProvider = data.for_org_provider;
  p.logoUrl = data.partner_org_logo_url;
  p.canBeAskedCustomQuestions = data.can_be_asked_custom_questions;
  p.slackChannel = data.slack_channel && SlackChannel.fromApi(data.slack_channel);
  p.salesCollateralLinks =
    data.sales_collateral_links && data.sales_collateral_links.map(SalesCollateralLink.fromApi);
  p.isDeleted = data.is_deleted;
  return p;
};

//--
// Partner Accounts
//--

type PartnerAccountApiData = {
  id: string,
  name: string,
  slug: string,
  past_customer: boolean,
  status_alert_text: string,
  status_alert_expires_at: string,
  website: string,
  status: string,
  closed_at: string,
};

class PartnerAccount {
  constructor(
    id: string,
    name: string,
    statusRaw: string,
    closedAtDate: ?Date,
    pastCustomer: boolean,
    statusAlertText: string,
    statusAlertExpiresAt: string,
    website: string,
    slug: string
  ) {
    this.id = id;
    this.name = name;
    this.statusRaw = statusRaw;
    this.status = getStatusComponent(statusRaw, pastCustomer);
    this.statusStr = getStatusStr(statusRaw, pastCustomer);
    this.closedAt = closedAtDate;
    this.pastCustomer = pastCustomer;
    this.statusAlertText = statusAlertText;
    this.statusAlertExpiresAt = statusAlertExpiresAt;
    this.website = website;
    this.slug = slug;
  }

  id: string;

  name: string;

  website: string;

  statusRaw: string;

  status: React.Node;

  statusStr: string;

  pastCustomer: boolean;

  closedAt: ?Date;

  statusAlertText: string;

  statusAlertExpiresAt: string;

  slug: string;

  static fromApi: (data: PartnerAccountApiData) => PartnerAccount;
}

PartnerAccount.fromApi = (data: PartnerAccountApiData): PartnerAccount => {
  const {
    status,
    closed_at: closedAt,
    id,
    name,
    past_customer: pastCustomer,
    status_alert_expires_at: statusAlertExpiresAt,
    status_alert_text: statusAlertText,
    website,
    slug,
  } = data;
  const closedAtDate = closedAt ? new Date(closedAt) : null;
  return new PartnerAccount(
    id,
    name,
    status,
    closedAtDate,
    pastCustomer,
    statusAlertText,
    statusAlertExpiresAt,
    website,
    slug
  );
};

//--
// AccountOwners
//--

class AccountOwner extends MinimalUser {
  id: string;

  orguserId: string;

  firstName: string;

  lastName: string;

  email: string;

  role: string;

  title: string;

  lastActivityAt: ?Date;

  messaged: boolean;

  avatarImage: string;

  signals: ?AccountSignals;

  inviteable: boolean;

  static fromApi: (data: any) => AccountOwner;

  static fromApiArray: (data: any) => AccountOwner[];
}

AccountOwner.fromApi = (d: any): AccountOwner => {
  const ao = new AccountOwner();
  ao.id = d.id;
  ao.orguserId = d.orguser_id;
  ao.firstName = d.first_name;
  ao.lastName = d.last_name;
  ao.lastActivityAt = d.last_activity_at && new Date(d.last_activity_at);
  ao.avatarImage = d.avatar_image;
  ao.email = d.email;
  ao.role = d.role;
  ao.title = d.title;
  ao.messaged = d.messaged;
  ao.signals = d.signals ? AccountSignals.fromApi(d.signals) : null;
  ao.inviteable = d.inviteable;
  return ao;
};

AccountOwner.fromApiArray = (dataArr): AccountOwner[] => dataArr.map(AccountOwner.fromApi);

class AccountOverlap {
  constructor(isCalculated: boolean, segments: { total: number }) {
    this.isCalculated = isCalculated;
    this.segments = camelizeKeys(segments);
  }

  isCalculated: boolean;

  segments: {
    total: number,
  };

  static fromApi(data: any): AccountOverlap {
    const { is_calculated: isCalc, segments } = data;
    return new AccountOverlap(isCalc, segments);
  }

  static blank(): AccountOverlap {
    return new AccountOverlap(false, { total: 0 });
  }
}

class AssigneeCounts {
  isAssigned: number;

  isUnassigned: number;

  static fromApi(data: any): AssigneeCounts {
    const ac = new AssigneeCounts();
    ac.isAssigned = data.is_assigned;
    ac.isUnassigned = data.is_unassigned;
    return ac;
  }
}

export {
  AccountOverlap,
  AccountOwner,
  AssigneeCounts,
  ConversationSettings,
  ExclusivePartnerFilters,
  OverlapSharingSettings,
  Partner,
  PartnerAccount,
  PartnerBasic,
  PartnerOrgUser,
};

export type { ExclusivePartnerFilter, OverlapSharingSetting };
