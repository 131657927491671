// @flow

import React, { useMemo, useRef, useState } from 'react';
import { Button, Input, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { take } from 'lodash';

import type { CrossbeamPartner } from 'data/entities/crossbeam';
import Org from 'data/entities/orguser';

import CompanyLogo from 'components/CompanyLogo';
import FieldErrors from 'components/FieldErrors';
import Modal from 'components/Modal';

type Props = {
  partners: CrossbeamPartner[],
  selectedPartners: string[],
  org: Org,
  setSelectedPartners: any,
  next: () => void,
};

const SelectPartners = ({ partners, next, selectedPartners, setSelectedPartners, org }: Props) => {
  const [search, setSearch] = useState('');
  const overLimit = selectedPartners.length > org.settings.customPartnersMax;
  const ref = useRef();
  const availablePartnersExceedLimit = partners.length >= org.settings.customPartnersMax;

  // Filter on search.
  const filteredPartners = useMemo(
    () =>
      search === ''
        ? partners
        : partners.filter(({ name, domain }) => name?.includes(search) || domain?.includes(search)),
    [partners, search]
  );

  const beingDisabledCount = useMemo(
    () =>
      partners.filter((p) => p.syncEnabled).filter((p) => !selectedPartners.includes(p.id)).length,
    [selectedPartners, partners]
  );
  const [hideIsOpen, setHideIsOpen] = useState(false);
  const toggleHide = () => setHideIsOpen(!hideIsOpen);

  const nextStep = () => {
    if (beingDisabledCount) {
      toggleHide();
    } else {
      next();
    }
  };

  const selectedAll = selectedPartners.length === partners.length && partners.length !== 0;
  const toggleSelectAll = () => {
    const partnerIds = (selectedAll ? partners.filter((p) => p.imported) : partners).map(
      (p) => p.id
    );
    setSelectedPartners(take(partnerIds, org.settings.customPartnersMax));
  };

  const toggleSelect = (partnerId: string) => {
    setSelectedPartners((selection) =>
      selection.includes(partnerId)
        ? selection.filter((id) => id !== partnerId)
        : selection.concat([partnerId])
    );
  };

  return (
    <>
      <div className="d-flex flex-column align-items-center gap-30 flex-1">
        <div className="d-flex flex-row align-items-center gap-20">
          <div ref={ref} className="d-flex align-items-center">
            <button
              type="button"
              disabled={availablePartnersExceedLimit}
              className="btn btn-link d-flex flex-row align-items-center gap-10"
              onClick={toggleSelectAll}
            >
              <input
                type="checkbox"
                checked={selectedAll}
                disabled={availablePartnersExceedLimit}
              />
              <span className="disable-selection large-text bold gray-700 text-nowrap">
                Select All
              </span>
              {availablePartnersExceedLimit ? (
                <FontAwesomeIcon icon="circle-info" className="gray" size="sm" />
              ) : null}
            </button>
          </div>
          {availablePartnersExceedLimit ? (
            <UncontrolledTooltip placement="bottom" target={ref} delay={{ show: 50, hide: 50 }}>
              There is a max limit of {org.settings.customPartnersMax} partners, select the ones
              most active
            </UncontrolledTooltip>
          ) : null}
          <Input
            style={{ width: '450px' }}
            type="text"
            name="search"
            placeholder="🔎  Search partners"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="d-flex flex-column align-items-center gap-10" style={{ width: '650px' }}>
          {filteredPartners?.length ? (
            filteredPartners.map((partner) => {
              const undeselectable = false;
              const togglable = true;
              return (
                <div
                  key={partner.id}
                  tabIndex={0}
                  role="button"
                  onClick={() => togglable && toggleSelect(partner.id)}
                  onKeyPress={() => togglable && toggleSelect(partner.id)}
                  className={`disable-selection card card-body ${
                    togglable ? 'card-hoverable' : ''
                  } m-0 d-flex flex-row align-items-center gap-15`}
                >
                  <input
                    type="checkbox"
                    disabled={undeselectable}
                    checked={selectedPartners.includes(partner.id) || undeselectable}
                  />
                  <CompanyLogo
                    name={partner.name}
                    logoUrl={partner.logoUrl}
                    domain={partner.clearbitDomain}
                    size={48}
                    className=""
                  />
                  <div className="flex-fill d-flex flex-column">
                    <span className="large-text bold gray-700">{partner.name}</span>
                    <span className="large-text bold gray-500">{partner.domain}</span>
                  </div>
                  {partner.syncEnabled && (
                    <span className="large-text semi-bold green">Data already enabled</span>
                  )}
                </div>
              );
            })
          ) : (
            <span className="large-text bold gray-500">No partners were found.</span>
          )}
        </div>
      </div>
      <div className="crossbeam-footer text-center">
        <div className="d-flex flex-row align-items-center gap-20" style={{ width: '650px' }}>
          <div className="flex-fill">
            <FieldErrors
              errors={
                overLimit &&
                `Too many partners selected, maximum is ${org.settings.customPartnersMax}.`
              }
              noMargin
              color="danger"
            />
          </div>
          <span className="disable-selection normal-text semi-bold gray-500">
            {selectedPartners.length} Selected
          </span>
          <button
            type="button"
            className="btn btn-primary"
            disabled={overLimit || selectedPartners.length === 0}
            onClick={nextStep}
          >
            Continue
          </button>
        </div>
      </div>
      <Modal
        isOpen={hideIsOpen}
        toggle={toggleHide}
        title="Hide Partner Data"
        footer={
          <>
            <Button color="secondary" onClick={toggleHide}>
              Cancel
            </Button>
            <Button color="danger" onClick={next}>
              Confirm
            </Button>
          </>
        }
        centered
      >
        <div className="d-flex flex-column gap-10">
          <span className="large-text bold gray-700">
            You are about to hide data for {beingDisabledCount} partner
            {beingDisabledCount === 1 ? '' : 's'} from your team.
          </span>
          <span className="normal-text semi-bold gray-600">
            Please note that after you hide these partners you will be able to show their accounts
            again later and will not lose any of your conversations.
          </span>
        </div>
      </Modal>
    </>
  );
};

export default SelectPartners;
