// @flow

import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import itly from 'itly';

import { BdRequest, Org, OrgUser } from 'data/entities';
import { SharedConversation } from 'data/entities/bdrequest';
import { addRequestComment, setupSharedConversation } from 'data/repositories/bdrequests';
import withOrguser from 'contexts/withOrguser';

import FieldErrors from 'components/FieldErrors';

import ApproveConversation from '../BdRequestDetail/ApproveConversation';
import AssignPartnerManager from '../BdRequestDetail/AssignPartnerManager';
import IntendedParticipantBanner from '../BdRequestDetail/IntendedParticipantBanner';
import SetupCrossbeamConversation from '../BdRequestDetail/SetupCrossbeamConversation';
import SlackThreadBanner from '../BdRequestDetail/SlackThreadBanner';

import InputArea from './InputArea';
import Visibility from './Visibility';

type Props = {
  loggedInOrguser: OrgUser,
  loggedInOrg: Org,
  bdRequest: BdRequest,
  sharedConversation: SharedConversation | null,
  onUpdate: () => void,
  onNewComment: (commentId: string, newComment: any, refreshRequest: boolean) => void,
  mini?: boolean,
};

const AddBdRequestComment = ({
  onNewComment,
  loggedInOrguser,
  loggedInOrg,
  sharedConversation,
  onUpdate,
  bdRequest,
  mini,
}: Props) => {
  const { partner, id: requestId, isShared, slackThreadUrl, isOffsite, status } = bdRequest;
  const slackAble = slackThreadUrl || isOffsite;
  const isSlackThread = useMemo(() => isShared && slackAble, [slackAble, isShared]);

  const [submitIsShared, setSubmitIsShared] = useState(isSlackThread ? false : isShared);
  const [text, setText] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [partnerAccountId, setPartnerAccountId] = useState(bdRequest.sharedPartnerAccountId);
  const [errors, setErrors] = useState({});

  const handleCommentResponse = (comment, refreshRequest) => {
    setText('');
    setSubmitting(false);
    itly.userClicksMessage({
      context: {
        created_at: comment.created_at,
        comment_id: comment.id,
        text: comment.text,
      },
      in_slack: isSlackThread,
      organization_id: loggedInOrg.id,
      partner_org_id: comment.partner_org_id,
      status,
      type: isShared ? 'external' : 'internal',
    });
    onNewComment(comment.id, comment, refreshRequest);
  };

  const addNewComment = () => {
    setErrors({});
    addRequestComment(requestId, text, submitIsShared)
      .then((comment) => handleCommentResponse(comment, false))
      .catch((error) => {
        setSubmitting(false);
        setErrors(error.json);
      });
  };

  // This needs to be tied with an ability to know if there is a matching account, and able to show that mapping
  // We want people to ensure they're creating them for the right PARTNER account
  const setupConvoAddComment = () => {
    setErrors({});
    setupSharedConversation(requestId, partnerAccountId, text)
      .then(() => {
        handleCommentResponse({}, true);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors(error.json || {});
      });
  };

  const onSubmitComment = (event) => {
    event.preventDefault();
    if (submitting || !text) {
      return;
    }
    const doAdd = submitIsShared && !isShared ? setupConvoAddComment : addNewComment;
    setSubmitting(true);
    doAdd();
  };

  const onChangeText = (event) => {
    setText(event.target.value);
  };

  return (
    <div className="p-3 d-flex flex-column gap-10">
      {bdRequest.partner &&
        !bdRequest.partner.isConnected &&
        !loggedInOrg.settings.frictionlessMessaging && (
          <SetupCrossbeamConversation
            request={bdRequest}
            onUpdate={() => {
              onUpdate();
              setSubmitIsShared(true);
            }}
          />
        )}
      {bdRequest.partner &&
        bdRequest.pendingApproval &&
        (bdRequest.partner.isConnected || loggedInOrg.settings.frictionlessMessaging) && (
          <ApproveConversation
            request={bdRequest}
            onUpdate={(approved) => {
              onUpdate();
              setSubmitIsShared(approved);
            }}
          />
        )}
      {bdRequest.partner && !bdRequest.pendingApproval && bdRequest.isUnassigned && (
        <AssignPartnerManager request={bdRequest} />
      )}
      {bdRequest.partner &&
        loggedInOrguser.capabilities.canManagePartnerships &&
        sharedConversation && (
          <IntendedParticipantBanner
            request={bdRequest}
            sharedConversation={sharedConversation}
            onUpdate={() => onUpdate()}
          />
        )}
      {isSlackThread && !bdRequest.isRejected ? (
        <SlackThreadBanner request={bdRequest} />
      ) : (
        <Visibility
          pendingApproval={bdRequest.pendingApproval}
          isShared={submitIsShared}
          partner={partner}
        />
      )}
      <InputArea
        mini={mini}
        isShared={submitIsShared}
        bdRequestShared={isSlackThread ? false : bdRequest.isShared}
        text={text}
        partnerAccountId={partnerAccountId}
        partner={bdRequest.partner}
        accountSlug={bdRequest.account && bdRequest.account.slug}
        onPartnerAccountChange={(pA) => setPartnerAccountId(pA?.id)}
        onChangeText={onChangeText}
        onChangeIsShared={setSubmitIsShared}
        bdRequestId={requestId}
        errors={errors}
        startSharedDisabled={slackAble}
        submitButton={
          <div className="d-flex flex-row align-items-center gap-10">
            <button
              onClick={onSubmitComment}
              type="submit"
              className={classNames(
                'AddRequestSubmitButton',
                !submitIsShared && 'AddRequestSubmitButtonInternal'
              )}
              disabled={submitting}
            >
              <FontAwesomeIcon icon="paper-plane" className="mr-1" /> Send
            </button>
          </div>
        }
      />
      <FieldErrors errors={errors && errors[0]} />
      <FieldErrors errors={errors && errors.nonFieldErrors} />
    </div>
  );
};

AddBdRequestComment.defaultProps = {
  mini: false,
};

export default withOrguser(AddBdRequestComment);
