// Change this to your locally created custom Sales Edge Crossbeam Integration
const CROSSBEAM_INTEGRATION_CLIENT_ID = '8j9R98rvHOBC0iI8Znj0OGckUmgw1HpA';

const config = {
  REACT_APP_ENVIRONMENT: 'development',
  CROSSBEAM_INTEGRATION_CLIENT_ID,
  REACT_APP_CROSSBEAM_DATA_AUTH_URL: `https://crossbeam-dev.auth0.com/authorize?audience=http://api.crossbeam-dev.com&response_type=code&client_id=${CROSSBEAM_INTEGRATION_CLIENT_ID}&redirect_uri=https%3A%2F%2Fsales.crossbeam-dev.com%2Foauth%2Fcrossbeam-data&scope=offline_access%20openid%20read%3Areports%20read%3Apartnerships`,

  REACT_APP_BACKEND_API_URL: 'https://sales-backend-api.crossbeam-dev.com',
  REACT_APP_CROSSBEAM_API_URL: 'https://api.crossbeam-dev.com',
  REACT_APP_CROSSBEAM_LOGIN_URL: 'https://app.crossbeam-dev.com/login',
  REACT_APP_CROSSBEAM_SWITCH_ORG_URL: 'https://app.crossbeam-dev.com/home',
  REACT_APP_CROSSBEAM_URL: 'https://app.crossbeam-dev.com',

  REACT_APP_SLACK_CLIENT_ID: '446648993123.4211861615926',
  REACT_APP_RECAPTCHA_SITE_KEY: '6Lck4NwaAAAAALNA-n81PLu4wSi5_g5zGBwvxdbD',

  ENVIRONMENT: 'development',
};

export default config;
