// @flow

import { datadogRum } from '@datadog/browser-rum';
import itlySdk from 'itly';

import { Org, OrgUser } from 'data/entities';
import { environmentName, isLocalDev, isProduction } from 'utils/environment';
import { enableFullStory } from 'utils/fullstory';

export function initializeTrackingLibraries() {
  // Utilized in the index.js to initialize the tracking libraries
  if (isProduction) {
    datadogRum.init({
      applicationId: 'e6af8149-e8ff-4955-b17d-f14289ef7e7c',
      clientToken: 'pubc0364f4501abd372e477233be4b1287b',
      site: 'datadoghq.com',
      service: 'salesedge-web',
      environment: environmentName,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      allowFallbackToLocalStorage: true,
      allowedTracingUrls: [
        'localhost',
        'sales.crossbeam.com',
        'sales.cb.c7m.io',
        'sales.crossbeam-dev.com',
        /^\//,
      ],
      beforeSend(event) {
        if (
          event.type === 'error' &&
          [
            'timeout of 0ms exceeded',
            'Network Error',
            'Non-Error exception captured',
            'Non-Error promise rejection',
          ].some((e) => event.error.message.includes(e))
        ) {
          return false;
        }
        return true;
      },
    });
    datadogRum.startSessionReplayRecording();
  }

  const itlyLoadOptions = {
    environment: isProduction ? 'production' : environmentName,
    destinations: {
      segment: {
        // We add full story ourselves, and don't use the one connected to Segment
        integrations: { FullStory: false, Fullstory: false, 'Fullstory (Actions)': false },
      },
    },
  };
  itlySdk.load(itlyLoadOptions);
}

export function identifyOrgUsers(orguser: OrgUser, org: Org, intercomHash: string): boolean {
  // Utilized in App.jsx to identify the orguser, when they become known or updated
  if (isLocalDev || !orguser) {
    return false;
  }
  const { id, email, crossbeamId } = orguser;
  // Identify Datadog user
  datadogRum.setUser({ id, email, username: email });

  if (!orguser.isGhost) {
    // Register Segment identity and group (skip for "ghost" users)
    itlySdk.identify(
      crossbeamId,
      { email },
      {
        segment: {
          // We add full story ourselves, and don't use the one connected to Segment
          integrations: { FullStory: false, Fullstory: false, 'Fullstory (Actions)': false },
        },
      }
    );
    // Initialize FullStory with user
    enableFullStory(orguser, org);
    return true;
  }
  return false;
}
