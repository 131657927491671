/* eslint-disable max-classes-per-file */
// @flow

import { Integration } from 'data/entities/chug';
import { PARTNER_IMPORT_LIMIT } from 'utils/environment';

import User from './user';

class RequestsWorkflowSettings {
  allowCustomQuestions: boolean;

  talkingPoints: string[];

  static fromApi = (data: any): RequestsWorkflowSettings => {
    const s = new RequestsWorkflowSettings();
    s.allowCustomQuestions = data.allow_custom_questions;
    s.talkingPoints = data.talking_points;
    return s;
  };
}

class VisibilitySettings {
  hideAccountStatus: boolean;

  static fromApi = (data: any): VisibilitySettings => {
    const vs = new VisibilitySettings();
    if (!data) {
      return vs;
    }
    vs.hideAccountStatus = data.hide_account_status;
    return vs;
  };
}

class SlackIntegration {
  domain: string;

  url: string;

  teamId: string;

  lastSynced: Date;

  createdAt: Date;

  static fromApi = (data: any): SlackIntegration | undefined => {
    if (!data) {
      return undefined;
    }
    const si = new SlackIntegration();
    si.domain = data.domain;
    si.url = data.url;
    si.teamId = data.team_id;
    si.lastSynced = data.last_synced;
    si.createdAt = data.created_at;
    return si;
  };
}

class OrgSettings {
  logSalesforceTasks: boolean;

  attributionEnabled: boolean;

  enableAttributionPrompts: boolean;

  taskAttributionDateField: string;

  unfilteredTaskLogging: boolean;

  frictionlessMessaging: boolean;

  conversationsOnSharedSlackChannels: boolean;

  requestsWorkflow: boolean;

  requestsWorkflowSettings: RequestsWorkflowSettings;

  inviteUsersToSlackChannel: boolean;

  publicDisplaysOfCoSelling: boolean;

  customMaxPartners: int;

  static fromApi = (d: any): OrgSettings => {
    const s = new OrgSettings();
    s.attributionEnabled = d.attribution_enabled;
    s.enableAttributionPrompts = d.enable_attribution_prompts;
    s.logSalesforceTasks = d.log_salesforce_tasks;
    s.taskAttributionDateField = d.task_attribution_date_field;
    s.unfilteredTaskLogging = d.unfiltered_task_logging;
    s.frictionlessMessaging = d.frictionless_messaging;
    s.conversationsOnSharedSlackChannels = d.conversations_on_shared_slack_channels;
    s.inviteUsersToSlackChannel = d.invite_users_to_slack_channel;
    s.publicDisplaysOfCoSelling = d.public_displays_of_co_selling;
    s.requestsWorkflow = d.requests_workflow;
    s.requestsWorkflowSettings =
      d.requests_workflow_settings &&
      RequestsWorkflowSettings.fromApi(d.requests_workflow_settings);
    s.customPartnersMax = d.custom_partners_max || PARTNER_IMPORT_LIMIT;
    return s;
  };
}

class Org {
  id: string;

  crossbeamOrgId: string;

  sourceUuid: string;

  adminId: string;

  name: string;

  description: string;

  slug: string;

  url: string;

  lastIntegration: ?Integration;

  hasIntegration: boolean;

  hasPartner: boolean;

  hasUsers: boolean;

  hasSlackIntegration: boolean;

  slackIntegration: ?SlackIntegration;

  subscriptionLevel: ?string;

  logoAvatar: ?string;

  visibilitySettings: VisibilitySettings;

  settings: OrgSettings;

  integrationStatus: ?string;

  signUpAt: ?string;

  logoUrl: ?string;

  static fromApi: (data: any) => Org;

  get hasIntegration() {
    return !!this.lastIntegration;
  }

  get integrationStatus() {
    return this.lastIntegration && this.lastIntegration.status;
  }

  get isIntegrationSetup() {
    return !!this.lastIntegration && this.lastIntegration.isActive;
  }
}

Org.fromApi = (data: any) => {
  const o = new Org();
  o.id = data.id;
  o.crossbeamOrgId = data.crossbeam_org_id;
  o.sourceUuid = data.source_uuid;
  o.adminId = data.admin_id;
  o.name = data.name;
  o.description = data.description;
  o.slug = data.slug;
  o.url = data.url;
  o.hasPartner = data.has_partner;
  o.hasUsers = data.has_users;
  o.subscriptionLevel = data.subscription_level;
  o.hasSlackIntegration = data.has_slack_integration;
  o.slackIntegration = SlackIntegration.fromApi(data.slack_integration);
  o.logoAvatar = data.logo_avatar;
  o.visibilitySettings = VisibilitySettings.fromApi(data.visibility_settings);
  o.settings = data.settings && OrgSettings.fromApi(data.settings);
  o.signUpAt = data.sign_up_at;
  o.lastIntegration = data.last_integration && Integration.fromApi(data.last_integration);
  o.logoUrl = data.logo_url;
  return o;
};

class DiscoveredOrg {
  id: ?string;

  name: string;

  domain: string;

  headcount: number;

  partnerManagers: User[];

  static fromApi: (data: any) => DiscoveredOrg;
}

DiscoveredOrg.fromApi = (data: any) => {
  const o = new DiscoveredOrg();
  o.id = data.id;
  o.name = data.name;
  o.domain = data.url;
  o.headcount = data.headcount;
  o.partnerManagers = data.partner_managers && data.partner_managers.map(User.fromApi);
  return o;
};

class RequestWorkflowSummary {
  talkingPointsCount: number;

  requestQuestionsCount: number;

  requestWorkflowSettingsChangedAt: Date;

  lastRequestQuestionChangedAt: Date;

  lastChangeAt: Date;

  static fromApi: (data: any) => RequestWorkflowSummary;
}

RequestWorkflowSummary.fromApi = (data: any) => {
  const s = new RequestWorkflowSummary();
  s.talkingPointsCount = data.talking_points_count;
  s.requestQuestionsCount = data.request_questions_count;
  s.requestWorkflowSettingsChangedAt =
    data.request_workflow_settings_changed_at &&
    new Date(data.request_workflow_settings_changed_at);
  s.lastRequestQuestionChangedAt =
    data.last_request_question_changed_at && new Date(data.last_request_question_changed_at);
  s.lastChangeAt = data.last_change_at && new Date(data.last_change_at);
  return s;
};

class CoSellingHealth {
  customQuestionsCount: number;

  needsCustomQuestions: number;

  partnersWithSlackChannels: number;

  partnersWithCollateralLinks: number;

  totalPartners: number;

  static fromApi = (d: any): CoSellingHealth => {
    const c = new CoSellingHealth();
    c.customQuestionsCount = d.custom_questions_count;
    c.needsCustomQuestions = d.needs_custom_questions;
    c.partnersWithSlackChannels = d.partners_with_slack_channels;
    c.partnersWithCollateralLinks = d.partners_with_collateral_links;
    c.totalPartners = d.total_partners;
    return c;
  };
}

export default Org;
export { CoSellingHealth, DiscoveredOrg, RequestWorkflowSummary, VisibilitySettings };
