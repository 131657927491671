/* eslint-disable max-classes-per-file */
// @flow

import * as React from 'react';

import { AccountOwner, PartnerAccount, PartnerOrgUser } from 'data/entities/partner';
import { getStatusComponent, getStatusStr } from 'data/entities/status';

import { SalesCollateralLink } from './salesCollateralLink';
import { AccountSignals } from './signal';

export class AccountPartnerBase {
  id: string;

  orgId: string;

  slug: string;

  name: string;

  domain: string;

  isConnected: boolean;

  bdManagerAtPartner: ?PartnerOrgUser;

  logoUrl: ?string;

  canBeAskedCustomQuestions: boolean;

  salesCollateralLinks: ?(SalesCollateralLink[]);

  static fromApi: (data: any) => AccountPartnerBase;
}

AccountPartnerBase.fromApi = ({
  id,
  slug,
  partner_org_name: name,
  description,
  partner_org_domain: domain,
  partner_org_logo_url: logoUrl,
  partner_org_id: orgId,
  is_connected: isConnected,
  can_be_asked_custom_questions: canBeAskedCustomQuestions,
  sales_collateral_links: salesCollateralLinks,
  bd_manager_at_partner: bdManagerAtPartner,
}: any): AccountPartnerBase => {
  const e = new AccountPartnerBase();
  e.id = id;
  e.domain = domain;
  e.description = description;
  e.name = name;
  e.slug = slug;
  e.isConnected = isConnected;
  e.logoUrl = logoUrl;
  e.orgId = orgId;
  e.canBeAskedCustomQuestions = canBeAskedCustomQuestions;
  e.salesCollateralLinks =
    salesCollateralLinks && salesCollateralLinks.map(SalesCollateralLink.fromApi);
  e.bdManagerAtPartner = bdManagerAtPartner && PartnerOrgUser.fromApi(bdManagerAtPartner);
  return e;
};

export class AccountPartnerList {
  id: string;

  partner: AccountPartnerBase;

  status: string;

  requestCount: number;

  partnerAccount: PartnerAccount;

  ownAccount: PartnerAccount;

  static fromApi: (data: any) => AccountPartnerList;
}

AccountPartnerList.fromApi = ({
  id,
  partner,
  request_count: requestCount,
  partner_account: partnerAccount,
  own_account: ownAccount,
}: any): AccountPartnerList => {
  const e = new AccountPartnerList();
  e.id = id;
  e.partner = AccountPartnerBase.fromApi(partner);
  e.requestCount = requestCount;
  e.partnerAccount = partnerAccount && PartnerAccount.fromApi(partnerAccount);
  e.ownAccount = ownAccount && PartnerAccount.fromApi(ownAccount);
  return e;
};

class Account {
  id: string;

  name: string;

  hidden: boolean;

  slug: string;

  status: React.Node;

  statusStr: string;

  statusRaw: ?string;

  salesforceUrl: ?string;

  accountOwners: ?(AccountOwner[]);

  pastCustomer: boolean;

  parentAccount: ?Account;

  signals: ?AccountSignals;

  statusAlertText: string;

  statusAlertExpiresAt: string;

  excluded: boolean;

  website: string;

  accountPartners: ?(AccountPartnerList[]);

  displayDealAmount: ?string;

  websiteNormalized: ?string;

  isTargetValue: ?string;

  crmUrl: ?string;

  provider: ?string;

  overlapsCount: ?number;

  contactsCount: number;

  get dashboardUrl(): string {
    return `/a/${this.slug}`;
  }

  get isTarget(): boolean {
    return !!this.isTargetValue;
  }

  static fromApi: (data: any) => Account;
}

Account.fromApi = (data: any): Account => {
  const {
    id,
    name,
    hidden,
    slug,
    status,
    salesforce_url: salesforceUrl,
    accountowners,
    past_customer: pastCustomer,
    parent_account: parentAccount,
    status_alert_expires_at: statusAlertExpiresAt,
    status_alert_text: statusAlertText,
    excluded,
    website,
    website_normalized: websiteNormalized,
    account_partners: accountPartners,
    display_deal_amount: displayDealAmount,
    is_target_value: isTargetValue,
    crm_url: crmUrl,
    overlaps_count: overlapsCount,
    provider,
    signals,
    contacts_count: contactsCount,
  } = data;
  const a = new Account();
  a.id = id;
  a.name = name;
  a.hidden = hidden;
  a.slug = slug;
  a.statusRaw = status;
  a.status = getStatusComponent(status, pastCustomer);
  a.statusStr = getStatusStr(status, pastCustomer);
  a.salesforceUrl = salesforceUrl;
  a.pastCustomer = pastCustomer;
  a.accountOwners = accountowners ? accountowners.map(AccountOwner.fromApi) : [];
  a.parentAccount = parentAccount ? Account.fromApi(parentAccount) : null;
  a.signals = signals ? AccountSignals.fromApi(signals) : null;
  a.statusAlertText = statusAlertText;
  a.statusAlertExpiresAt = statusAlertExpiresAt;
  a.excluded = excluded;
  a.website = website;
  a.websiteNormalized = websiteNormalized;
  a.accountPartners = accountPartners ? accountPartners.map(AccountPartnerList.fromApi) : [];
  a.displayDealAmount = displayDealAmount;
  a.isTargetValue = isTargetValue;
  a.crmUrl = crmUrl;
  a.provider = provider;
  a.overlapsCount = overlapsCount;
  a.contactsCount = contactsCount;
  return a;
};

export default Account;

export const RELEVANCE_ALL = 'all';
export const RELEVANCE_PARTNER_MANAGER = 'partner_manager';
export const RELEVANCE_SALES_PERSON = 'sales_person';
export const FILTER_RELEVANCE_OPTIONS = {
  [RELEVANCE_PARTNER_MANAGER]: 'As Partner Manager',
  [RELEVANCE_SALES_PERSON]: 'As Account Owner',
  [RELEVANCE_ALL]: 'All',
};
