// @flow

import React, { useEffect, useState } from 'react';

import { OrgUser } from 'data/entities';
import { fetchNextOrgusers, fetchOrgusers } from 'data/repositories/orgusers';

import LoadingRing from 'components/LoadingRing';

import SuggestionCard from './SuggestionCard';

const Suggestions = () => {
  const [loading, setLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false);
  const [suggestions, setSuggestions] = useState<OrgUser[]>([]);

  useEffect(() => {
    setLoading(true);
    setSuggestions([]);
    fetchOrgusers({ needInvite: true })
      .then((sp) => {
        setLoading(false);
        setHasNext(sp.next);
        setSuggestions(sp.results.filter((s) => s.email));
      })
      .catch(() => {
        setLoading(false);
        setHasNext(false);
      });
  }, []);

  const getNext = () =>
    hasNext &&
    fetchNextOrgusers(hasNext).then((res) => {
      setSuggestions((prevOrgusers) => {
        const { results: newOrgusers } = res;
        const hasExisting = prevOrgusers && prevOrgusers.length;
        setHasNext(res.next);
        const filteredNewOrgusers = newOrgusers.filter((s) => s.email);
        return hasExisting ? prevOrgusers.concat(filteredNewOrgusers) : filteredNewOrgusers;
      });
    });

  return suggestions.length !== 0 || loading ? (
    <div className="d-flex flex-column gap-20" style={{ width: 400, maxWidth: 400 }}>
      <h5 className="m-0 p-0 bold gray-700 text-nowrap">Account owners you should invite:</h5>
      <div className="d-flex flex-column gap-10">
        {!loading ? (
          suggestions.map((orguser: OrgUser) => (
            <SuggestionCard
              key={orguser.id}
              avatarImage={orguser.avatarImage}
              firstName={orguser.firstName}
              lastName={orguser.lastName}
              overlapsCount={orguser.overlapsCount}
              email={orguser.email}
            />
          ))
        ) : (
          <LoadingRing maxWidth="50px" className="m-5" />
        )}
      </div>
      {hasNext && (
        <div>
          <button className="btn btn-link btn-block text-primary" type="button" onClick={getNext}>
            Load More
          </button>
        </div>
      )}
    </div>
  ) : null;
};

export default Suggestions;
